import axios from 'axios';

export default class VkRequests {
  filtredParametr = 'sub';

  ip = '';

  startPagination = 0;

  maxCount = 0;

  buttonsCount = 1;

  filter = {
    countFrom: 0,
    countTo: 49,
    filtredParametr: 'sub',
    currentDate: 'day',
    sortBy: 't',
    auditoryСountFrom: 0,
    auditoryСountTo: 100000000,
    auditoryDiapazoneFrom: 0,
    auditoryDiapazoneTo: 100000000,
    groupType: 'all',
    verificator: '', // dont used now
    statusType: '', // dont used now
    catalogs: '', // dont used now
    choose: '',
    adminId: '',
    lid: '&lid=',
  };

  resultJSON = [];

  callbackFunck= null;

  constructor(listId = '') {
    if (!listId) {
      this.filter.lid = '';
      return;
    }
    this.filter.lid = `&lid=${listId}`;
  }

  setAdminsId(adminId = '') {
    if (!adminId) {
      this.filter.adminId = '';
      return;
    }

    this.filter.adminId = `&admin=${adminId}`;
  }

  async requestAuditory() {
    const requestString = `${this.ip}/gstat?time=${this.filter.currentDate}`;

    await axios.get(requestString)
      .then((responce) => {
        // TODO if dont use slider delete diapazone
        this.filter.auditoryDiapazoneTo = Number.parseInt(
          responce.data.stat_count.max_subs_count, 10,
        );
        this.filter.auditoryСountTo = Number.parseInt(responce.data.stat_count.max_subs_count, 10);

        this.filter.auditoryDiapazoneFrom = 0;
        this.filter.auditoryСountFrom = 0;
      })
      .catch((err) => {
        console.log(err);
      });
  }

  getGroupsJSONByFilter({ updatedParam, newParamValue }) {
    this.updateFilterParametr(updatedParam, newParamValue);
    return this.getGroupsJSON();
  }

  updateFilterParametr(updatedParam, newParamValue) {
    if (!updatedParam) {
      return;
    }

    this.filter[updatedParam] = newParamValue;
  }

  async getGroupsJSONBySort(params) {
    this.filter.filtredParametr = params.filtredParametr;
    this.filter.sortBy = params.sortBy ? 't' : 'f';

    return this.getGroupsJSON();
  }

  getGroupsJSON() {
    const requestString = `${this.ip}/getgroups?fcount=${this.filter.countFrom}&tcount=${this.filter.countTo}&par=${this.filter.filtredParametr}&time=${this.filter.currentDate}&rev=${this.filter.sortBy}&fsub=${this.filter.auditoryСountFrom}&tsub=${this.filter.auditoryСountTo}&type=${this.filter.groupType}${this.filter.verificator}${this.filter.statusType}${this.filter.catalogs}${this.filter.choose}${this.filter.adminId}${this.filter.lid}`;

    return new Promise((resolve, reject) => {
      axios.get(requestString).then((responce) => {
        if (responce.data.message === 'no groups') {
          resolve(this.resultJSON);
          return;
        }
        this.resultJSON = responce.data;
        const buffer = this.resultJSON.pop() || '';

        if (Array.isArray(buffer)) {
          const element = buffer.find(((el) => el.groups_count));
          this.maxCount = element?.groups_count ?? 0;
        } else {
          this.maxCount = buffer?.groups_count ?? 0;
        }

        this.calcButtonsCount(this.maxCount);

        resolve(this.resultJSON);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  calcButtonsCount(maxCount) {
    if (this.filter.countFrom === 0 && this.filter.countTo <= 50) {
      this.buttonsCount = Math.ceil((maxCount) / 50);
      this.getButtonsCount();
    }
  }

  getPaginstionState() {
    return this.startPagination;
  }

  setPaginationState(value) {
    this.startPagination = value;
  }

  setCallbackFunc(f) {
    this.callbackFunck = f;
  }

  async getGroupsJSONBySearch(value) {
    this.resetFilterParam();

    await this.requestAuditory();

    if (value === '') {
      this.filter.choose = value;
    } else {
      this.filter.choose = `&gname=${value}`;
    }

    return this.getGroupsJSON();
  }

  getButtonsCount() {
    if (this.callbackFunck) {
      this.callbackFunck(this.buttonsCount);
    }
  }

  get propName() {
    // геттер, срабатывает при чтении obj.propName
    return this.buttonsCount;
  }

  updatePageByPagination(increment) {
    if (increment === 1) {
      this.filter.countFrom += 50;
      this.filter.countTo = 50 + this.filter.countTo > this.maxCount ? this.maxCount
        : 50 + this.filter.countTo;
    }

    if (increment === 2) {
      this.filter.countFrom += 100;
      this.filter.countTo = 100 + this.filter.countTo > this.maxCount ? this.maxCount
        : 100 + this.filter.countTo;
    }

    if (increment === -2) {
      if (this.filter.countTo === this.maxCount) {
        this.filter.countTo = this.filter.countFrom - 100 + 49;
      } else {
        this.filter.countTo = this.filter.countTo - 100 < 49 ? 49 : this.filter.countFrom - 100;
      }

      this.filter.countFrom = this.filter.countFrom - 100 < 0 ? 0 : this.filter.countFrom - 100;

      if (this.filter.countFrom < 0) this.filter.countFrom = 0;
      if (this.filter.countTo < 49) this.filter.countTo = 49;
      if (this.filter.countFrom > 0 && this.filter.countFrom < 49) {
        this.filter.countFrom = 0;
        this.filter.countTo = 49;
      }
    }

    if (increment === -1) {
      if (this.filter.countTo === this.maxCount) {
        this.filter.countTo = this.filter.countFrom - 1;
      } else {
        this.filter.countTo -= 50;
      }

      this.filter.countFrom -= 50;
      if (this.filter.countFrom < 0) this.filter.countFrom = 0;
      if (this.filter.countTo < 49) this.filter.countTo = 49;
      if (this.filter.countFrom > 0 && this.filter.countFrom < 49) {
        this.filter.countFrom = 0;
        this.filter.countTo = 49;
      }
    }

    if (increment === -10) {
      this.filter.countFrom = 0;
      this.filter.countTo = 49;
    }

    if (increment === 10) {
      const calcStartCount = (+this.buttonsCount - 1) * 50;
      this.filter.countFrom = calcStartCount;
      this.filter.countTo = this.maxCount;
    }

    this.startPagination = this.filter.countFrom;
    return this.getGroupsJSON();
  }

  resetFilterParam() {
    const { lid } = this.filter;

    this.filter = {
      countFrom: 0,
      countTo: 49,
      filtredParametr: 'sub',
      currentDate: 'day',
      sortBy: 't',
      auditoryСountFrom: 0,
      auditoryСountTo: 100000000,
      auditoryDiapazoneFrom: 0,
      auditoryDiapazoneTo: 100000000,
      groupType: 'all',
      verificator: '',
      statusType: '',
      catalogs: '',
      choose: '',
      adminId: '',
      lid,
    };
  }

  updateGroupByFilter({ gid, filter }) {
    return new Promise((resolve, reject) => {
      axios.get(`${this.ip}/getgroupsbyid?time=${filter}&gid=${gid}`)
        .then((resp) => resolve(resp.data))
        .catch((err) => reject(err));
    });
  }
}
