<template>
  <div class="filter-radio">
    <p :style="{ 'font-size': fontSize, color: textColor }" class="filter-radio__title filters">
      {{ title }}
    </p>

    <radio-buttons
      :items="buttons.list"
      :groupName="buttons.groupName"
      @updateItem="updateItem"
    ></radio-buttons>
  </div>
</template>

<script>
import RadioButtons from '@/components/atom/button/radio/RadioButtons.vue';

export default {
  components: {
    RadioButtons,
  },
  emits: ['updateItem'],
  props: {
    title: {
      type: String,
      default: 'Filter',
    },
    buttons: {
      type: Object,
      default() {
        return {};
      },
    },
    fontSize: {
      type: String,
      default: '',
    },
    textColor: {
      type: String,
      default: '',
    },
  },
  methods: {
    updateItem(currentId, groupName) {
      this.$emit('updateItem', currentId, groupName);
    },
  },
};
</script>

<style lang="scss" scoped>
.filter-radio {
  display: flex;
  flex-direction: column;

  align-items: flex-start;

  margin: 10px 0 0 0;
  width: 100%;

  &__title {
    color: #555;
    font-weight: 700;
    font-size: 18px;
  }
}
</style>
